@import '../../colors';

.footer-legal-info {
	$self: &;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	min-width: 319px;
	max-width: 570px;

	&--tablet {
		flex-direction: column;
		align-items: flex-end;

		#{$self}__item {
			font-size: 13px;
			line-height: 18px;

			&--first {
				margin: 0 0 11px 0;
			}
		}

		#{$self}__links-item:not(:last-child) {
			margin-right: 40px;
		}
	}

	&__item {
		font-size: 11px;
		line-height: 15px;
		color: $bluish-grey-55;

		&--first {
			margin-right: 36px;
		}

		@media (min-width: 1920px) {
			font-size: 13px;
			line-height: 18px;

			&--first {
				margin-right: 40px;
			}
		}
	}

	&__links {
		display: flex;
		flex-direction: row;

		&-item:not(:last-child) {
			margin-right: 26px;
		}

		@media (min-width: 1920px) {
			&-item:not(:last-child) {
				margin-right: 30px;
			}
		}
	}
}
