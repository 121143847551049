@mixin tablet-styles {
	align-items: center;
	justify-items: center;
	grid-template-columns: 1fr;
	min-height: 927px;
	background-size: 805px 365px;
	background-position: bottom 80px left 0;
	background-image: url('../../assets/Mountain_iPad.png');

	@media only screen and (min-device-width: 1024px) {
		padding: 27px 32px 30px 32px;
		background-size: 1360px 730px;
		background-position: bottom 10px left 0;
	}
}

.desktop-container {
	position: relative;
	display: grid;
	grid-template-columns: max-content 1fr;
	grid-template-rows: 1fr;
	width: 100%;
	height: 100%;
	min-height: 650px;
	background-color: white;
	background-image: url('../../assets/Mountain_big.png');
	background-size: 760px 365px;
	background-position: bottom 41px left -30px;
	background-repeat: no-repeat;

	@media (min-width: 1920px) {
		background-size: 1015px 488px;
		background-position: bottom 0px left 0;
	}

	&--tablet {
		@include tablet-styles();
	}
}
