@import '../../colors';

.mobile-page {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	row-gap: 25px;
	width: 100%;
	min-height: 300px;
	height: 100%;
	padding: 20px 20px 25px;
	background: linear-gradient(146.6deg, #69ECDB -45.95%, #2E95C0 90.49%);

	.main-content {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: 100%;
		height: unset;
		max-height: 100%;
		margin-bottom: 0;
		box-shadow: 0px 4px 30px $bluish-grey-20;
		border-radius: 14px;

		.mobile-header {
			flex-shrink: 0;
			justify-content: center;
			width: 100%;
			padding-left: 0;

			&__text-container {
				padding-left: 31px;
				max-width: 215px;
			}

			&__title {
				max-width: 140px;
			}
		}

		&__sections-container {
			padding: 14px 26px 27px 25px;

			.create-account-section__recommendation {
				font-size: 13px;
				line-height: 18px;
			}
		}


		&__text {
			font-size: 15px;
			line-height: 21px;
		}
	}

	&__footer {
		display: flex;
		align-items: flex-end;
		justify-content: center;

		.footer-legal-info {
			display: grid;
			grid-template-rows: repeat(2, 1fr);
			grid-row-gap: 10px;
			justify-content: center;
			justify-items: center;

			&__item {
				font-size: 13px;
				line-height: 18px;
				color: white;

				&:not(:last-child) {
					margin-right: 34px;
				}

				&--first {
					margin: 0;

					&:not(:last-child) {
						margin: 0;
					}
				}
			}
		}
	}

	&--invite-only {
		padding: 26px 16px 24px;

		.main-content {
			&__sections-container {
				grid-row-gap: 34px;
				padding: 17px 22px 28px 22px;

				.install-sharekey-section {
					&__buttons-container {
						margin: 8px 0 8px 1px;
					}

					&__button {
						width: 144px;
						height: 48px;
						max-height: 48px;

						&:last-child {
							padding-right: 14px;
						}
					}
				}

				.create-account-section {
					&__content {
						margin: 6px 0;
					}

					&__recommendation {
						margin-right: 20px;
					}
				}
			}
		}
	}

	&--member-key-only {
		padding: 26px 16px 24px;

		.main-content {
			.mobile-header {
				&__text-container {
					max-width: 250px;
				}

				&__text .lock-icon {
					margin-bottom: -1px;
				}
	
				&__title {
					max-width: 100%;
				}
			}

			.install-sharekey-section {
				&__recommendation {
					margin-top: 9px;
					font-size: 13px;
				}
			}
		}
	}
}
