.App {
	width: 100vw;
	max-width: 100vw;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	max-height: 100vh;
	max-height: calc(var(--vh, 1vh) * 100);
	overflow: hidden;

	&--desktop {
		min-height: 650px;
	}

	&--tablet {
		min-height: 927px;

		&.App--invite-only {
			min-height: unset;

			.desktop-container {
				min-height: unset;
			}
		}
	}

	&--mobile {
		min-height: 792px;

		&.App--invite-only {
			min-height: 550px;
		}
	}
}
