@import '../../colors';

.mobile-header {
	$self: &;
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	max-width: 100%;
	height: 112px;
	padding-top: 3px;
	padding-left: 42px;
	border-bottom: 1px solid $bluish-grey-10;

	&__title {
		font-weight: bold;
		font-size: 15px;
		line-height: 19px;
	}

	&__text {
		margin-top: 3px;
		font-size: 13px;
		line-height: 18px;
		color: $bluish-grey-70;
	}

	&__text-container {
		padding-left: 23px;
	}

	&__link {
		width: max-content;
		height: 56px;
	}

	&--tablet {
		height: 104px;

		#{$self}__title {
			font-size: 17px;
			line-height: 24px;
		}

		#{$self}__text {
			font-size: 15px;
			line-height: 21px;
		}
	}

	.lock-icon {
		path {
			fill: $bluish-grey-70;
		}
	}
}
