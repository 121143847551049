@import './colors';

body {
	margin: 0;
	font-family: 'HeroNew', sans-serif;
	color: $dark-grey;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	div {
		box-sizing: border-box;
	}

	h1 {
		margin: 0;
		margin-block-start: 0;
		margin-block-end: 0;
		font-size: 18px;
		line-height: 25px;
		font-weight: bold;
	}
}

@font-face {
	font-family: 'HeroNew';
	src: url(./fonts/HeroNewRegular.woff) format('woff'),
		url(./fonts/HeroNewRegular.ttf) format('truetype');
}

@font-face {
	font-family: 'HeroNew';
	src: url(./fonts/HeroNewBold.woff) format('woff'),
		url(./fonts/HeroNewBold.ttf) format('truetype');
	font-weight: bold;
}

@font-face {
	font-family: 'HeroNew';
	src: url(./fonts/HeroNewMedium.woff2) format('woff2'),
		url(./fonts/HeroNewMedium.woff) format('woff'),
		url(./fonts/HeroNewMedium.ttf) format('truetype');
	font-weight: 500;
}
