@import '../../colors';

.footer {
	$self: &;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: space-between;
	min-width: 0;
	max-width: 100vw;
	padding: 28px 32px;
	background: $white-70;
	box-shadow: 0px -5px 15px 5px $white-70;

	&__info {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 17px;
		width: 246px;
		max-width: 246px;
		height: 24px;

		&-item {
			display: flex;
			flex-direction: row;
			align-items: center;
			font-size: 10px;
			line-height: 11px;
			color: $sk-marine;

			&-text {
				margin-left: 6px;
			}

			&:last-child {
				margin-left: 8px;
			}
		}
	}

	&--tablet {
		padding: 27px 25px;

		#{$self}__info {
			grid-column-gap: 19px;
			width: 250px;
			max-width: 250px;
		}

		@media only screen and (min-device-width: 1024px) {
			box-shadow: 0px -10px 15px 30px $white-70;
		}
	}

	@media (min-width: 1920px) {
		background: $white;
		box-shadow: 0px -5px 15px 10px $white;
	}
}
