.desktop-header {
	display: flex;
	flex-direction: column;
	width: 240px;
	height: 100%;
	padding: 30px 32px;

	&__text {
		position: relative;
		margin-top: 73.4px;
		margin-bottom: 42px;
		font-weight: bold;
		font-size: 25px;
		line-height: 33px;

		&::after {
			content: '';
			position: absolute;
			bottom: -22px;
			left: 0;
			width: 51px;
			height: 2px;
			background-color: #3FC0C4;

			@media (min-width: 1920px) {
				bottom: -28px;
				width: 66px;
				height: 2.6px;
			}
		}
	}

	&__description {
		font-size: 12px;
		line-height: 17px;
	}

	&__link {
		width: max-content;
		height: 57px;
	}

	&--member-key-only {
		width: 292px;
	}

	.lock-icon {
		margin-bottom: -1px;
	}

	@media (min-width: 1440px) and (max-width: 1920px) {
		width: 292px;

		&--member-key-only {
			width: 320px;
		}
	}

	@media (min-width: 1920px) {
		width: 389px;

		&__text {
			margin-top: 185px;
			margin-bottom: 56.5px;
			font-size: 33px;
			line-height: 41px;
		}

		&__description {
			font-size: 16px;
			line-height: 22px;
		}

		.sk-logo {
			width: 203px;
			height: 64px;
		}

		.lock-icon {
			width: 14px;
			height: 14px;
		}
	}
}
