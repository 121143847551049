@import '../../colors';

.main-content {
	$self: &;
	width: 500px;
	max-width: 500px;
	height: 544px;
	max-height: 544px;
	margin-bottom: 35px;
	background: $white;
	box-shadow: 0px 0px 12px $bluish-grey-25;
	border-radius: 8px;

	&__sections-container {
		display: grid;
		grid-row-gap: 36px;
		width: 100%;
		padding: 36px 50px 35px 40px;
	}

	a {
		font-weight: 500;
		color: $sk-light-blue;
	}

	&__text {
		font-size: 14px;
		line-height: 20px;
	}

	&--tablet {
		width: 581px;
		max-width: 581px;
		height: 707px;
		max-height: 707px;
		margin-bottom: 100px;

		#{$self}__sections-container {
			grid-row-gap: 41px;
			padding-top: 22px;
			padding-left: 47px;

			#{$self}__text {
				font-size: 16px;
				line-height: 22px;
			}

			.install-sharekey-section__buttons-container {
				grid-column-gap: 4px;
				margin: 12px 0 7px -1px;

				.install-sharekey-section__button {
					width: 152px;
					height: 50px;
					min-height: 50px;

					&:last-child {
						padding-right: 15px;
					}
				}
			}

			.create-account-section {
				&__content {
					margin-top: 10px;
					margin-bottom: 8px;
				}

				&__recommendation {
					font-size: 14px;
					line-height: 20px;
				}
			}

			.connect-section__content {
				margin: 10px 0 12px;
			}
		}

		h1 {
			font-size: 20px;
			line-height: 28px;
		}

		@media only screen and (orientation: landscape) {
			margin-bottom: 93px;
		}
	}

	&--invite-only {
		position: relative;
		height: 326px;
		max-height: 326px;

		&.main-content--tablet {
			height: 462px;
			max-height: 462px;
			margin-bottom: 58px;

			#{$self}__sections-container {
				grid-row-gap: 44px;

				.install-sharekey-section__buttons-container {
					grid-column-gap: 4px;
					margin: 9px 0 10px -1px;
				}

				.create-account-section {
					&__content {
						margin: 6px 0;
					}
				}
			}
		}

		#{$self}__sections-container {
			padding-bottom: 40px;
		}
	}
}
