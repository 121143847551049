$dark-grey: #242B2E;
$white: white;
$white-70: rgba(255, 255, 255, 0.7);
$sk-light-blue: #0F8CC9;
$sk-marine: #3FC0C4;
$bluish-grey-10: rgba(53, 80, 105, 0.1);
$bluish-grey-20: rgba(53, 80, 105, 0.2);
$bluish-grey-25: rgba(53, 80, 105, 0.25);
$bluish-grey-55: rgba(53, 80, 105, 0.55);
$bluish-grey-70: rgba(53, 80, 105, 0.7);
