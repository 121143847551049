@import '../../mixins';

.connect-section {
	$self: &;

	&__content {
		margin: 8px 0 10px;
	}

	&__qrCode {
		position: relative;
		width: 101px;
		height: 101px;

		&--tablet {
			width: 115px;
			height: 115px;

			#{$self}__logo {
				bottom: 43px;
				left: 46px;
				width: 24px;
				height: 28px;
			}
		}

		&--mobile {
			@media only screen
			and (max-device-width: 700px) {
				width: 120px;
				height: 120px;

				#{$self}__logo {
					bottom: 45px;
					left: 49px;
					width: 24px;
					height: 28px;
				}
			}
		}
	}

	&__logo {
		position: absolute;
		bottom: 37px;
		left: 39px;
	}

	&__content-link {
		@include content-link;

		word-break: break-all;
	}
}
