@import '../../colors';

.install-sharekey-section {
	&__buttons-container {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 10px;
		margin: 10px 0 8px -1px;
	}

	&__button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 130px;
		height: 42px;
		box-sizing: border-box;
		padding: 1px 4px 0 0px;
		border: 1px solid $bluish-grey-20;
		border-radius: 5px;
		-webkit-transition: .2s ease-in;
		transition: .2s ease-in;

		&:last-child {
			padding: 0 10px 0 0;
		}

		&:hover {
			-webkit-box-shadow: -0.1px 4px 10px 0 $bluish-grey-20;
			box-shadow: -0.1px 4px 10px 0 $bluish-grey-20;
		}
	}

	&__recommendation {
		margin-top: 6px;
		font-size: 12px;
		line-height: 16px;
		color: rgba(53, 80, 105, 0.7);

		&--tablet {
			font-size: 14px;
		}
	}
}
