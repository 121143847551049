@import '../../mixins';

.create-account-section {
	&__content {
		margin: 8px 0 6px;

		&-code {
			font-weight: bold;
		}
	}

	&__recommendation {
		font-size: 12px;
		line-height: 16px;
		color: rgba(53, 80, 105, 0.7);
	}

	&__content-link {
		@include content-link;
	}
}
